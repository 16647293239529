<template>
  <v-layout
    v-if="isLoggedIn === false"
    fill-height
    class="login"
  >
    <!-- Left half -->
    <div
      :style="setBackgroundStyle"
      class="login__left-half"
    >
      <!-- Splash image -->
      <img
        :src="`${$xms.theme.login.splash}`"
        class="login__splash-img"
      >
    </div>
    <!-- Right half -->
    <v-flex class="login__right-half">
      <div class="login__logo">
        <img :src="`${$xms.theme.login.logo}`">
      </div>
      <div class="login__content">
        <!-- Forms (Login, Forgot Password, Reset Password) -->
        <router-view />
      </div>
      <!-- Signature -->
      <div class="login__signature">
        <span>{{ $t('global.developed_by') }}</span>
        <a
          href="https://ax2.ca/"
          target="_blank"
        ><img :src="'ax2-logo.svg'"></a>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
    setBackgroundStyle () {
      let backgroundStyle = `background: ${this.$xms.theme.global.primary}`;

      if (this.$xms.theme.login.gradient !== undefined) {
        backgroundStyle = `background: linear-gradient(${this.$xms.theme.login.gradient.rotate}, ${this.$xms.theme.login.gradient.start} 0%, ${this.$xms.theme.login.gradient.end} 100%)`;
      }

      return backgroundStyle;
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.login {
  display: flex;
  flex-direction: column;

  @media (min-width: $bp-md) {
    flex-direction: row;
  }
}

// Left half & Right half
.login__left-half {
  align-items: center;
}

.login__right-half {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
}

.login__left-half,
.login__right-half {
  display: flex;
  flex-direction: column;
  padding: $spacer;

  @media (min-width: $bp-md) {
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: $spacer*2;
  }
}

// Splash image
.login__splash-img {
  @include rem(max-width, 400px);

  @media (min-width: $bp-md) {
    @include rem(max-width, 600px);
  }
}

// Content
.login__content {
  width: 100%;

  @include rem(max-width, 400px);
}

// Logo
.login__logo {
  display: none;
  position: absolute;

  @include rem(top, 32px);
  @include rem(right, 32px);
  @include rem(max-width, 150px);

  @media (min-width: $bp-md) {
    display: block;
  }
}

// Signature
.login__signature {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;

  > span {
    opacity: 0.5;
  }

  > a {
    display: inline-block;

    @include rem(max-width, 70px);

    margin-left: $spacer/2;
  }

  @media (min-width: $bp-md) {
    margin: 0;
    position: absolute;

    @include rem(right, 32px);
    @include rem(bottom, 32px);
  }
}
</style>
